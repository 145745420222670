@import '/src/theme.css';

.character {
  pointer-events: all;
  padding: 8px;
  border-radius: 16px;
  font-size: 13px;
  font-family: var(--font-dnd);
  background-color: var(--main-dark-09);
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 8px 1px;
}

.character:hover {
  filter: brightness(120%);
}

.avatar {
  background-color: var(--main-dark);
  border: 1px var(--main-dark) solid;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}

.name {
  font-size: 20px;
}

.raceAndClass {
  color: #bbb;
}
