.diceHistory {
  /* display: flex;
  flex-direction: row;
  gap: 6px; */
  margin-bottom: 12px;
  width: 100%;
  overflow: auto;
}

.historyEntry {
  font-size: small;
  white-space: nowrap;
}
