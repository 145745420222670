@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 450px;
  min-width: 365px;
}

.avatarBack {
  background-color: var(--bg-color);
  border-radius: 4px;
  overflow: hidden;
}

.selectorContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: large;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
}

.smallLabel {
  font-size: small;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
}

.toolbarContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.toolButton {
  font-size: large;
  padding: 10px;
}

.raceMods {
  display: flex;
  flex-direction: row;
  font-size: 9pt;
  font-weight: 600;
  color: #06c6b9;
}

.classMods {
  display: flex;
  flex-direction: row;
  font-size: 9pt;
  font-weight: 600;
  color: #23ce6b;
}

.raceMod {
  font-weight: 600;
  color: #06c6b9;
}

.abilities {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.abilityInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
}

.abilityPointsHeader {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: medium;
  padding: 2px;
  min-height: 28px;
}

.remainingAbilityPoints {
  color: rgb(248, 91, 91);
  font-size: small;
}

.bounce {
  margin-top: 4px;
  animation: bounce 0.5s ease 2;
}

.mod {
  font-weight: 600;
  font-size: xx-large;
  color: var(--text-highlight);
}

@keyframes bounce {
  50% {
    transform: translateY(0%);
  }
  65% {
    transform: translateY(-15%);
  }
  80% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
