.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  font-size: x-large;
}

.label {
  font-size: medium;
  margin-top: 10px;
}

.gameTag {
  padding: 4px;
}
