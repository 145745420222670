.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  max-width: 450px;
}

@media only screen and (max-width: 400px) {
  .content {
    gap: 10px;
  }
}
