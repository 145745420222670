@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.selectorContent {
  margin: 6px 0;
}

.dice {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  margin: 6px 4px;
  background-repeat: no-repeat;
  background-position: center;
}

.count {
  position: absolute;
  right: 4px;
  bottom: 10px;
  color: #000;
  background-color: var(--alt-bg-color);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: small;
  font-weight: 700;
  line-height: 10px;
  margin-bottom: -6px;
}
