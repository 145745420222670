@import '/src/theme.css';

.container {
  padding: 12px;
  overflow: hidden;
  border-radius: 8px;
  background-color: hsla(233, 22%, 30%, 0.6);
  background-image: url('../../assets/images/cardWatermark.png');
  background-position: right 0 bottom 0;
  background-repeat: no-repeat;
  animation: fadeIn 0.3s;
}

.content {
  border: rgba(256, 256, 256, 0.2) 1px solid;
  border-radius: 6px;
  padding: 12px;
  background-color: hsla(233, 22%, 30%, 0.6); /* make it darker in the center */
  user-select: none;
}

.title {
  font-size: x-large;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
  border-bottom: rgba(256, 256, 256, 0.2) 1px solid;
}

.description {
  width: 100%;
  font-size: medium;
  color: #ccc;
  text-align: left;
  text-overflow: ellipsis;
  text-indent: 20px;
  white-space: pre-wrap;
  overflow-y: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
