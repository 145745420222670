body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'AlegreyaSC';
  src: local('AlegreyaSC'), url(./assets/fonts/Alegreya_SC/AlegreyaSC-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'AlegreyaSC';
  font-weight: 900;
  src: local('AlegreyaSC'), url(./assets/fonts/Alegreya_SC/AlegreyaSC-Bold.ttf) format('truetype');
}

/* Sometimes we need to read safe-area in code, that is a way to do it  */
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}
