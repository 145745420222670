@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.itemContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.item {
  width: 100%;
}

.fullWidthItem {
  width: 100%;
}

.title {
  font-size: x-large;
  margin-bottom: 12px;
}

.selected {
  box-shadow: inset 0px 0px 0px 4px var(--main-yellow);
}

.switcher {
  padding: 2px;
  width: 18px;
  height: 18px;
}
