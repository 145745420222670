@import '/src/theme.css';

.toolButton {
  font-size: large;
  padding: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.pointsInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
