.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  min-width: 300px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: large;
  margin-bottom: 12px;
}

.text {
  font-size: medium;
  text-decoration: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: text;
  background-color: white;
  color: var(--text-color-dark);
  border: 2px solid white;
  padding: 4px;
}

.text:focus-visible {
  outline: none;
  color: var(--text-color-dark);
  border: 2px solid var(--main-yellow);
  border-radius: 4px;
}

.button {
  padding: 6px 12px;
}

.closeButton {
  background-color: var(--alt-bg-color);
  box-shadow: none;
  padding: 6px;
  border-radius: 100px;
}
