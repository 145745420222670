@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--panel-bg);
  backdrop-filter: blur(10px);
  padding: 8px;
  border-radius: 12px;
  color: #fff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
  /* border: 1px solid #535353; */
  border: 2px solid var(--main-yellow-08);
  align-items: center;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px 8px 0 8px;
}

.rolled {
  display: flex;
  flex-direction: column;
  padding: 1px 10px 1px 10px;
  margin: 2px;
  border: 1px solid var(--button-border);
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 8px 1px var(--main-dark-01);
  border-radius: 4px;
  background-color: var(--button-bg);
  color: #ccc;
}

.mod {
  background-color: var(--main-yellow-08);
  color: var(--text-color-dark);
}

.score {
  font-size: large;
  font-weight: 500;
}

.plus {
  font-size: large;
  color: rgba(255, 255, 255, 0.4);
  margin: 0 4px;
}

.diceSource {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);
}

.scoreSource {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.finalScore {
  font-family: var(--font-dnd);
  font-size: 48px;
  color: var(--text-highlight);
  margin-top: -10px;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid red;
  transform: rotate(-30deg);
}

.strikethrough:after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid red;
  transform: rotate(30deg);
}
