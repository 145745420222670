@import '/src/theme.css';

.conteiner {
  padding-top: 10px;
  padding-bottom: 10px;

  flex-shrink: 0;
  flex-grow: 1;

  width: 100%;
  overflow-x: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.conteiner::-webkit-scrollbar {
  display: none;
}

.selected {
  background-color: var(--main-yellow-03);
}
