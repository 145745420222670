.container {
  /* Thta is needed; otherwise arrow will be selected in some cases. */
  user-select: none;
}

.content {
  position: absolute;
  left: env(safe-area-inset-left);
  top: env(safe-area-inset-top);
  right: env(safe-area-inset-right);
  bottom: env(safe-area-inset-bottom);
  z-index: 0;
}

.board {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
}

.arrow {
  animation-duration: 700ms;
  animation-fill-mode: forwards;
  animation-name: pulse;
  animation-iteration-count: infinite;
  overflow: visible;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0.3;
    transform: translateX(-100%);
  }
}
