@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--button-bg);
  border: 1px solid var(--button-border);
  /* box-shadow: 0px 0px 8px 1px var(--main-dark-01);  -- I do like to have it without shadow*/
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 4px;
  padding: 0;
  user-select: none;
  width: 100%;
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row-reverse;
}

.countContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.count {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: #000;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: small;
  font-weight: 700;
  line-height: 10px;
  margin-right: -6px;
  margin-bottom: -6px;
}

.button {
  font-size: 32px;
  width: 100%;
  padding: 0;
  border-radius: 0;
  box-sizing: content-box;
  margin: -1px;
}

.topButton {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottomButton {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.leftButton {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rightButton {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
