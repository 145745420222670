.container {
  text-align: center;
  background-image: url('../../assets/images/bg.jpg');
  background-position: right;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
