.badge {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  border: 1px solid white;
  color: white;
  user-select: none;
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
