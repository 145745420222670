@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 46px;
}

.heroName {
  font-size: x-large;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border: 1px solid var(--button-border);
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 8px 1px var(--main-dark-01);
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
  flex: 1;
  flex-shrink: 1;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
  background-color: var(--button-bg);
  color: white;
  text-align: center;
  cursor: text;
}

.heroName:focus-visible {
  outline: none;
  background-color: white;
  color: var(--text-color-dark);
  border: 2px solid var(--main-yellow);
  border-radius: 4px;
}

.heroName:hover {
  filter: brightness(150%);
}

.heroName:hover:focus-visible {
  filter: brightness(100%);
}
