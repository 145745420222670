.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: var(--panel-bg);
  backdrop-filter: blur(5px);
  color: #fff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
  border: 1px solid #535353;
  box-sizing: content-box;
  border-radius: 8px;
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
