.conteiner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.panel {
  padding: 8px;
  user-select: none;
  border-radius: 16px;
  background-color: var(--button-bg);
  border: 1px solid var(--button-border);
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 8px 1px var(--main-dark-01);
  transition: filter 0.2s;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: large;
  box-sizing: border-box;
}

.cardsLeft {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.darkBackground {
  background-color: var(--main-dark-09);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
