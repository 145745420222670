.container {
  position: absolute;
  top: 0;
  left: 12px;
  right: 12px;
  z-index: 20;
  margin-top: calc(10px + env(safe-area-inset-top));
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* .hero {
} */

.notification {
  min-width: 120px;
}

/* when screen is large, > 960px when panels start sliding from sides */
@media only screen and (min-width: 960px) {
  .hero {
    position: absolute;
    left: 0;
    top: 0;
  }

  .notification {
    max-width: 30%;
  }
}
