@import '/src/theme.css';

.title {
  margin-bottom: 12px;
  font-size: large;
}

.content {
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.name {
  font-size: x-large;
}

.mod {
  font-size: x-large;
  color: var(--text-highlight);
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 12px;
}

.importButton {
  background-color: #d64933;
  padding-left: 12px;
  padding-right: 12px;
}

.cancelButton {
  padding-left: 12px;
  padding-right: 12px;
}

.info {
  width: 100%;
  margin-top: 36px;
  text-align: center;
}

.charName {
  font-size: large;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
}

.abilities {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 12px;
  width: 100%;
}

.ability {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
