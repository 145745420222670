.container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  margin: 20px;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.toolbarItem {
  pointer-events: all;
}
