@import url(/src/theme.css);

.container {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  background-color: rgba(212, 188, 153, 0.8);
  border: 2px #a68055 solid;
  border-radius: 10px 10px 45px 45px;
  color: #404364;
  transition: linear 500ms;
  min-width: 90px;
  font-family: var(--font-dnd);
  font-weight: 400;
  width: 110px;
}

.container:hover {
  box-shadow: 0px 0px 0px 4px rgba(203, 107, 29, 0.7);
}

.selected {
  /* background-color: rgba(185, 161, 126, 0.8); */
  background-color: var(--main-yellow-08);
}

.pulse {
  animation: pulse 1.5s infinite;
}

.ability {
  font-size: 12px;
  font-weight: 600;
  padding: 6px 2px;
  border-bottom: 2px #a68055 solid;
  letter-spacing: 1px;
}

.abilityValue {
  font-size: x-large;
  padding: 0 10px 0;
  margin-bottom: 4px;
  border-radius: 20px;
  display: inline-block;
  background-color: rgba(166, 128, 85, 0.5);
}

.mod {
  font-size: xx-large;
  font-weight: 500;
  padding: 0 20px 10px;
}

@keyframes pulse {
  0% {
    /* transform: scale(0.95); */
    box-shadow: 0px 0px 0px 0px rgba(203, 107, 29, 0.2);
  }

  50% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 4px rgba(203, 107, 29, 0.7);
  }

  100% {
    /* transform: scale(0.95); */
    box-shadow: 0px 0px 0px 0px rgba(203, 107, 29, 0.2);
  }
}
