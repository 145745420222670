:root {
  color-scheme: dark; /* makes scrollbars and other UI elements dark */

  --main-yellow: rgb(224, 202, 60);
  --main-yellow-01: rgba(224, 202, 60, 0.1);
  --main-yellow-02: rgba(224, 202, 60, 0.2);
  --main-yellow-03: rgba(224, 202, 60, 0.3);
  --main-yellow-04: rgba(224, 202, 60, 0.4);
  --main-yellow-05: rgba(224, 202, 60, 0.5);
  --main-yellow-06: rgba(224, 202, 60, 0.6);
  --main-yellow-07: rgba(224, 202, 60, 0.7);
  --main-yellow-08: rgba(224, 202, 60, 0.8);
  --main-yellow-09: rgba(224, 202, 60, 0.9);

  --main-dark: rgb(45, 48, 71);
  --main-dark-01: rgba(45, 48, 71, 0.1);
  --main-dark-02: rgba(45, 48, 71, 0.2);
  --main-dark-03: rgba(45, 48, 71, 0.3);
  --main-dark-04: rgba(45, 48, 71, 0.4);
  --main-dark-05: rgba(45, 48, 71, 0.5);
  --main-dark-06: rgba(45, 48, 71, 0.6);
  --main-dark-07: rgba(45, 48, 71, 0.7);
  --main-dark-08: rgba(45, 48, 71, 0.8);
  --main-dark-09: rgba(45, 48, 71, 0.9);

  --accent: rgba(131, 136, 175, 0.1);

  --main-content-bg: #b59472;

  --panel-bg: var(--main-dark-08, fuchsia);
  --button-bg: var(--accent, fuchsia);
  --button-disabled-bg: transparent;
  --button-border: var(--accent, fuchsia);

  --bg-color: var(--main-dark-04, fuchsia);
  --alt-bg-color: var(--main-yellow, fuchsia);
  --text-color-dark: var(--main-dark, fuchsia);
  --text-color-light: #e6e6e6;
  --text-highlight: var(--main-yellow, fuchsia);

  --font-dnd: AlegreyaSC, serif;

  /* --text-color-light: #777676bb; */
  /* --link-color: #543fd7; */
  /* --masthead-color: #543fd7; */
  /* --masthead-text: #fff; */
  /* --button-color: #263238; */
  /* --button-text: #fff; */
}
