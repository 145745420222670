@import '/src/theme.css';

.button {
  padding: 4px;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  background-color: var(--button-bg);
  border: 1px solid var(--button-border);
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 8px 1px var(--main-dark-01);
  transition: filter 0.2s;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: large;
  box-sizing: border-box;
}

.button:hover {
  filter: brightness(150%);
}

.button:active {
  filter: brightness(70%);
}

.disabled {
  opacity: 0.1;
  pointer-events: none;
  background-color: var(--button-disabled-bg);
}
