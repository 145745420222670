@import '/src/theme.css';

.swiperContainer {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventoryContainer {
  overflow-y: auto;
  padding: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-sizing: border-box;
  user-select: none;
  width: 100%;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 1;
}

.inventoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 82px);
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 10px;
  margin: 3px;
  justify-content: center;
}

.closeButton {
  margin: 20px;
  margin-top: 0;
  background-color: var(--alt-bg-color);
  box-shadow: none;
  padding: 16px;
  flex-grow: 0;
}

.item {
  cursor: pointer;
  user-select: none;
  width: 82px;
  height: 82px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  outline: 3px solid var(--main-dark-03);
  background-color: var(--main-dark-03);
  box-sizing: border-box;
  border-radius: 6px;
  transition: border 500ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  overflow: hidden;
  font-size: small;
  text-align: center;
}

.item:hover {
  border: 1px solid var(--main-yellow-08);
}

.selected {
  outline: 3px solid var(--main-yellow-08);
}

.card {
  width: 260px;
  height: 350px;
}

.cardSlide {
  margin: 10px 0;
  flex-shrink: 1 !important;
}

.inactive {
  backdrop-filter: none;
  background-color: hsla(233, 22%, 30%, 1);
}
