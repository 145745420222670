.container {
  padding: 12px;
  border-radius: 200px;
  background-color: var(--main-dark-09);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
  transition: filter 0.3s;
  box-sizing: border-box;
  position: relative;
  backdrop-filter: blur(10px);
}

.container:hover {
  filter: brightness(120%);
}

.container:active {
  filter: brightness(70%);
}

.badge {
  position: absolute;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
}
