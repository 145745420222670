@import url(/src/theme.css);

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 280px;
}

.menuButton {
  width: 100%;
  padding: 18px 0;
}

.title {
  color: white;
  font-size: xx-large;
  font-family: var(--font-dnd);
  letter-spacing: 1px;
  margin-bottom: 20px;
}
