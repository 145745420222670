@import '/src/theme.css';

.container {
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
  font-size: large;
}

.item {
  flex: 1;
}

.count {
  text-align: center;
  padding: 6px 0;
  /* width: 100%; */
  font-weight: 700;
}
