@import '/src/theme.css';

.title {
  font-size: small;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
}

.result {
  font-size: xx-large;
  font-family: var(--font-dnd);
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: -0.2em;
}

.term {
  font-weight: 500;
  font-size: medium;
  font-family: var(--font-dnd);
}

.baseColor {
  color: #23ce6b;
}

.abilityColor {
  color: var(--text-highlight);
}

.raceColor {
  color: #06c6b9;
}

.classColor {
  color: #23ce6b;
}

.signColor {
  color: #fff;
  opacity: 0.3;
}

.modName {
  font-size: 9pt;
  line-height: 0.7;
}
