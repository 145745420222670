.container {
  cursor: pointer;
  font-size: large;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.container:hover {
  filter: brightness(180%);
}

.container:active {
  filter: brightness(70%);
}

.checkbox {
  width: 28px;
  height: 28px;
}
