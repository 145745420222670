@import '/src/theme.css';

.outer {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  /* opacity: 0.5; */
}

.outer > div {
  pointer-events: all;
}

.outerLeft {
  justify-content: flex-start;
}

.outerRight {
  justify-content: flex-end;
}

.outerBottom {
  align-items: flex-end;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: var(--panel-bg);
  backdrop-filter: blur(10px);
  color: #fff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
  border: 1px solid #535353;
  box-sizing: border-box;
  animation-duration: 300ms;
  width: 400px;
  max-height: 100%;
  /* padding-top: env(safe-area-inset-top); */
}

.topToolbar {
  flex-direction: column-reverse;
}

.bottomToolbar {
  flex-direction: column;
}

.panelBottom {
  animation-name: slideFromBottom;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding-top: 0;
}

/* should be below .panelBottom class definition */
.fullScreen {
  height: 100%;
  padding-top: env(safe-area-inset-top);
}

.panelRight {
  animation-name: slideFromRight;
}

.panelLeft {
  animation-name: slideFromLeft;
}

.floating {
  border-radius: 12px;
  margin: 8px;
  margin-top: max(8px, env(safe-area-inset-top));
  margin-bottom: max(8px, env(safe-area-inset-bottom));
}

.cover {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.content {
  flex: 1;
  padding: 12px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* it should be flex-start, otherwise scroll will not work correctly*/
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.toolbar {
  display: flex;
  padding: 0 19px 19px;
  box-sizing: border-box;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.floating .toolbar {
  padding: 11px; /*on floating panels we have 8px margins*/
  padding-top: 4px;
}

.toolbarPlaceholder {
  width: 56px; /* closeButton.width + closeButton.padding */
  height: 56px; /* closeButton.height + closeButton.padding */
}

.closeButton {
  background-color: var(--alt-bg-color);
  box-shadow: none;
  padding: 16px;
  align-self: flex-end;
}

@keyframes slideFromBottom {
  from {
    opacity: 0;
    transform: translate(0, 100%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translate(100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translate(-100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
